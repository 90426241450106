import React, { useState, useEffect, useCallback } from 'react'
import update from 'immutability-helper'
import * as blobUtil from 'blob-util'
import Axios from 'axios'
import { getUnixTime } from 'date-fns'
import jsPDF from 'jspdf'

import './Annotation.scss'
import Toolbox from './components/Toolbox/Toolbox'
import Tooltip from './components/Tooltip/Tooltip'
import PanelComment from './components/PanelComment/PanelComment'
import { ShapeContext } from './context/shapeContext'
// import ContextMenu from './components/ContextMenu/ContextMenu';
import { PreviewContext } from './context/previewContext'
import { TooltipContext } from './context/tooltipContext'
import {
  shapeClassname,
  toolboxActionHandler,
  toolboxColorPalette,
  positionCommentPlace,
  typeDisplayAnnotation,
  baseMarginPage,
  attachmentTypeStartFromStruct,
  orientationStruct,
  pdfAnnotationModules,
} from './shared/constants'
import PanelVersion from './components/PanelVersion/PanelVersion'
import HeaderAnnotation from './components/HeaderAnnotation/HeaderAnnotation'
import { HeaderAnnotationContext } from './context/headerAnnotationContext'
import { ContextMenuContext } from './context/contextMenuContext'
import { AnnotationActionContext } from './context/annotationActionContext'
import Loader from './components/Loader/Loader'
import LoaderFile from './components/Loader/LoaderFile'
import NotSupported from './components/NotSupported/NotSupported'
import { VersionContext } from './context/versionContext'
import { createTopicService } from '../../services/annotationService/createTopicService'
import { commentTopicService } from '../../services/annotationService/commentTopicService'
import { editCommentService } from '../../services/annotationService/editCommentService'
import { deleteCommentService } from '../../services/annotationService/deleteCommentService'
import { deleteTopicService } from '../../services/annotationService/deleteTopicService'
import { editTopicService } from '../../services/annotationService/editTopicService'
import { annotationResolveService } from '../../services/annotationService/annotationResolveService'
import Navigation from './components/Navigation/Navigation'
import {
  revertToCanvasDimentionPosition,
  centeringPDFByHeight,
  convertToScreenPosition,
  heightHeader,
  marginHeader,
} from './shared/utils'
import toastCenter, { toastMessageType } from '../../shared/toastCenter'
import AnnotationViewer from './components/AnnotationViewer/AnnotationViewer'
import { LoaderContext } from './context/loaderContext'
import { ReviewerContext } from './context/reviewerContext'
import userRole from '../../shared/userRole'
import { PdfContext } from './context/pdfContext'
import { ImgContext } from './context/imgContextValue'
import useEventListener from './hooks/eventListener'
import { getOrientation } from './shared/zoomHelper'

import {
  getDark,
  getImage,
  backdrop,
  generateWatermark,
} from './shared/watermarkHelper'
import { downloadProofService } from '../../services/annotationService/downloadProofService'

let timeoutOpenViewComment = null
let toastMessageWarningOpenView = null

const Annotation = ({
  isLoading,
  onDownloadFile,
  onOpenNewTab,
  onCopyFileUrl,
  onSendComment,
  onSaveEditComment,
  onDeleteComment,
  onResolveComment,
  onReopenComment,
  onChangeVersion,
  onClose,
  onNavNext,
  onNavPrev,
  onActionMakeChange,
  onActionStayInReview,
  onActionPutOnHold,
  onActionOrderApproved,
  listMentions = [],
  data = { internal: [], public: [] },
  isLoadAnnotation = true,
  listVersion = [],
  viewComment = {
    queryVersionId: null,
    queryCommentId: null,
    queryPublic: null,
  },
  useNavigation = false,
  useVersion = true,
  useClose = true,
  currentUser = null,
  onApproveVersion = null,
  onReviseVersion = null,
  ability = [],
  isPreview = false,
  orderProgress = '',
  currentUserRole = '',
  isInternalTabDisabled = false,
  isPublicTabDisabled = false,
  attachmentTypeStartFrom = attachmentTypeStartFromStruct.SUPPLIED_FILE,
  isStartingScreenPublic = true,
  byteLength = 0,
  downloadWithWatermark = null,
  onDeleteAttachmentVersion = null,
  onSilentUpdate,
}) => {
  const { queryVersionId, queryCommentId, queryPublic } = viewComment

  //#region State
  const [isLoadingFile, setIsLoadingFile] = useState(true)
  const [isLoadingPage, setIsLoadingPage] = useState(true)
  const [loadedPercent, setLoadedPercent] = useState('0%')

  const [toolboxAction, setToolboxAction] = useState({
    type: shapeClassname.CIRCLE,
    handler: toolboxActionHandler.MOUSE_CLICK,
    color: toolboxColorPalette.BLUE,
  })
  const [draggedPosition, setDraggedPosition] = useState(null)
  const [isCloseTooltipEvent, setIsCloseTooltipEvent] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const [isShowCommentPanel, setIsShowCommentPanel] = useState(true)
  const [isShowPublicComment, setIsShowPublicComment] = useState(false)
  const [isShowVersionPanel, setIsShowVersionPanel] = useState(false)
  const [transitionProperty, setTransitionProperty] = useState('none')
  const [imageBoundRect, setImageBoundRect] = useState({
    x: 0,
    y: 0,
    relativeX: 0,
    relativeY: 0,
    naturalWidth: null,
    naturalHeight: null,
    width: null,
    height: null,
    scale: 0.75,
  })
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 })
  const [selectedTopic, setSelectedTopic] = useState(null)
  const [isShowTooltip, setIsShowTooltip] = useState(false)
  const [tooltipPlace, setTooltipPlace] = useState(positionCommentPlace.TOP)
  const [isShapeShowed, setIsShapeShowed] = useState(true)
  const [selectedVersion, setSelectedVersion] = useState(null)
  const [activePagePdf, setActivePagePdf] = useState(0)
  const [totalPagePdf, setTotalPagePdf] = useState(1)
  const [canvasObjects, setCanvasObjects] = useState([])
  const [versions, setVersions] = useState({ internal: [], public: [] })
  const [
    selectedInternalVersionIndex,
    setSelectedInternalVersionIndex,
  ] = useState(0)
  const [selectedPublicVersionIndex, setSelectedPublicVersionIndex] = useState(
    0
  )

  // panel thread comment
  const [commentThreadPanel, setCommentThreadPanel] = useState([])

  // customer and operator comments
  const [publicComments, setPublicComments] = useState([])

  // internal (app) comments
  const [internalComments, setInternalComments] = useState([])

  const [selectedAttrId, setSelectedAttrId] = useState('')
  const [isShowcontextMenu, setIsShowcontextMenu] = useState(false)
  const [positionContextMenu, setPositionContextMenu] = useState({ x: 0, y: 0 })

  const [layerPdfScroll, setLayerPdfScroll] = useState({ x: 0, y: 0 })
  const [typeDisplay, setTypeDisplay] = useState(
    typeDisplayAnnotation.UNSUPPORT
  )
  const [url, setUrl] = useState('')
  const [selectedAnnotation, setSelectedAnnotation] = useState(null)

  /**
   * @deprecated at @version 0.2
   * this for old annotation canvas
   */
  const [marginPage, setMarginPage] = useState(baseMarginPage)

  const [pdfViewerWrappState, setPdfViewerWrappState] = useState(null)

  const [activeDrawingArea, setActiveDrawingArea] = useState('')
  const [hasUnsavedComment, setHasUnsavedComment] = useState(false)
  const [zoomDetail, setZoomDetail] = useState({
    initialScale: 0,
    pageFitScale: 0,
    pageHeightScale: 0,
    pageWidthScale: 0,
    orientation: '',
    scaleList: [],
  })

  const [pdfViewerState, setPdfViewerState] = useState(null)
  const [imgRefState, setImgRefState] = useState(null)

  const [isReadOnly, setIsReadOnly] = useState(false)
  const [isOpenViewComment, setIsOpenViewComment] = useState(false)
  const [isNewVersionCanvas, setIsNewVersionCanvas] = useState(false)
  const [isDownloadFile, setIsDownloadFile] = useState(false)
  const [pdfAnnotationModule, setPdfAnnotationModule] = useState(null)
  //#endregion

  //#region Use Effect
  useEffect(() => {
    if (
      queryVersionId &&
      queryCommentId &&
      (typeof queryPublic !== undefined || queryPublic !== null)
    ) {
      setIsOpenViewComment(true)
      timeoutOpenViewComment = null
      toastMessageWarningOpenView = null
    }
  }, [queryVersionId, queryCommentId, queryPublic])

  useEffect(() => {
    if (isPreview) {
      setIsShowTooltip(false)
      setToolboxAction({
        type: shapeClassname.MOUSE,
        handler: toolboxActionHandler.MOUSE_CLICK,
        color: toolboxColorPalette.BLUE,
      })
    }

    setIsReadOnly(isPreview)
  }, [isPreview])

  useEffect(() => {
    setIsLoadingPage(isLoading)
  }, [isLoading])

  // hiding shape when loading file
  useEffect(() => {
    setIsShapeShowed(!isLoadingFile)
  }, [isLoadingFile])

  // load first time for select tab public/internal
  useEffect(() => {
    if (
      currentUserRole === userRole.OWNER ||
      currentUserRole === userRole.STAFF ||
      currentUserRole === userRole.GUEST
    ) {
      setIsShowPublicComment(true)
    } else {
      setIsShowPublicComment(isStartingScreenPublic)
    }
  }, [currentUserRole, isStartingScreenPublic])

  // load annotations
  useEffect(() => {
    if (!isLoadingFile) {
      const defaultHeader = document.getElementsByClassName(
        'header-annotation-wrapper'
      )
      if (defaultHeader && defaultHeader.length > 0) {
        if (pdfAnnotationModule === pdfAnnotationModules.pdfTools) {
          defaultHeader[0].style.setProperty(
            'visibility',
            'hidden',
            'important'
          )
        } else {
          defaultHeader[0].style.setProperty(
            'visibility',
            'visible',
            'important'
          )
        }
      }

      const defaultHeaderMobile = document.getElementsByClassName(
        'header-annotation-wrapper-mobile'
      )
      if (defaultHeaderMobile && defaultHeaderMobile.length > 0) {
        if (pdfAnnotationModule === pdfAnnotationModules.pdfTools) {
          defaultHeaderMobile[0].style.setProperty(
            'visibility',
            'hidden',
            'important'
          )
        } else {
          defaultHeaderMobile[0].style.setProperty(
            'visibility',
            'visible',
            'important'
          )
        }
      }

      if (pdfAnnotationModule !== pdfAnnotationModules.pdfTools) {
        const internalCommentsTemp = data.internal
        const publicCommentsTemp = data.public

        if (isShowPublicComment) {
          if (publicCommentsTemp.length > 0) {
            setCanvasObjects((_) => {
              return publicCommentsTemp.map((x) => {
                const annotation =
                  x.annotation !== '' ? JSON.parse(x.annotation) : null
                return {
                  ...annotation,
                  isUnsaved: false,
                  unsavedComment: '',
                }
              })
            })

            // check rendering canvas
            const tempAnn =
              publicCommentsTemp[0].annotation !== ''
                ? JSON.parse(publicCommentsTemp[0].annotation)
                : null
            setIsNewVersionCanvas(
              tempAnn && tempAnn.attrs.version && tempAnn.attrs.version > 0.1
            )
          } else {
            setCanvasObjects([])
            setIsNewVersionCanvas(true)
          }
        } else {
          if (internalCommentsTemp.length > 0) {
            setCanvasObjects((_) => {
              return internalCommentsTemp.map((x) => {
                const annotation =
                  x.annotation !== '' ? JSON.parse(x.annotation) : null
                return {
                  ...annotation,
                  isUnsaved: false,
                  unsavedComment: '',
                }
              })
            })

            // check rendering canvas
            const tempAnn =
              internalCommentsTemp[0].annotation !== ''
                ? JSON.parse(internalCommentsTemp[0].annotation)
                : null
            setIsNewVersionCanvas(
              tempAnn && tempAnn.attrs.version && tempAnn.attrs.version > 0.1
            )
          } else {
            setCanvasObjects([])
            setIsNewVersionCanvas(true)
          }
        }

        if (isShowPublicComment) {
          if (publicCommentsTemp.length > 0) {
            const publicThreads = publicCommentsTemp.map((x) => {
              const annotate =
                x.annotation !== '' ? JSON.parse(x.annotation) : null
              const topic = {
                id: x.id,
                message: x.message,
                user: x.sender,
                isTopic: true,
                dateCreated: x.dateCreated,
              }
              const messages = [...x.comments]
              messages.forEach((m) => (m.isTopic = false))
              messages.splice(0, 0, topic)

              return {
                id: x.id,
                annotationAttrId: annotate.attrs.id,
                isResolve: x.isResolve,
                threads: messages,
              }
            })

            setPublicComments(publicThreads)
            setCommentThreadPanel(publicThreads)
          } else {
            setPublicComments([])
            setCommentThreadPanel([])
          }
        } else {
          if (internalCommentsTemp.length > 0) {
            const internalThreads = internalCommentsTemp.map((x) => {
              const annotate =
                x.annotation !== '' ? JSON.parse(x.annotation) : null
              const topic = {
                id: x.id,
                message: x.message,
                user: x.sender,
                isTopic: true,
                dateCreated: x.dateCreated,
              }
              const messages = [...x.comments]
              messages.forEach((m) => (m.isTopic = false))
              messages.splice(0, 0, topic)

              return {
                id: x.id,
                annotationAttrId: annotate.attrs.id,
                isResolve: x.isResolve,
                threads: messages,
              }
            })

            setInternalComments(internalThreads)
            setCommentThreadPanel(internalThreads)
          } else {
            setInternalComments([])
            setCommentThreadPanel([])
          }
        }

        setHasUnsavedComment(false)
      }
    }
  }, [
    currentUserRole,
    data,
    isLoadingFile,
    isShowPublicComment,
    pdfAnnotationModule,
  ])

  useEffect(() => {
    const subscriptionNewTopic = createTopicService
      .reload()
      .subscribe((val) => {
        if (isShowTooltip) {
          const topic = {
            id: val.id,
            message: val.message,
            user: val.user,
            isTopic: true,
            dateCreated: val.dateCreated,
          }

          const messages = []
          messages.push(topic)

          setSelectedTopic({
            topicId: val.id,
            isResolve: false,
            comments: messages,
          })
        }
      })

    return () => {
      subscriptionNewTopic.unsubscribe()
    }
  }, [isShowTooltip])

  useEffect(() => {
    const subscriptionNewComment = commentTopicService
      .reload()
      .subscribe((val) => {
        if (isShowTooltip && selectedTopic !== null) {
          const newMessage = {
            id: val.id,
            message: val.message,
            user: val.user,
            isTopic: false,
            dateCreated: val.dateCreated,
          }

          const messages = [...selectedTopic.comments]
          messages.push(newMessage)

          setSelectedTopic({
            topicId: val.topicId,
            isResolve: selectedTopic.isResolve,
            comments: messages,
          })
        }
      })

    const subscriptionEditComment = editCommentService
      .reload()
      .subscribe((val) => {
        if (isShowTooltip && selectedTopic !== null) {
          const messages = [...selectedTopic.comments]
          const index = messages.findIndex((x) => x.id === val.id)
          if (index > -1) {
            messages[index].message = val.message
            messages[index].dateCreated = val.dateCreated

            setSelectedTopic({
              topicId: val.topicId,
              isResolve: selectedTopic.isResolve,
              comments: messages,
            })
          }
        }
      })

    const subscriptionDeleteComment = deleteCommentService
      .reload()
      .subscribe((val) => {
        if (isShowTooltip && selectedTopic !== null) {
          const messages = [...selectedTopic.comments]
          const index = messages.findIndex((x) => x.id === val.id)
          if (index > -1) {
            messages.splice(index, 1)

            setSelectedTopic({
              topicId: val.topicId,
              isResolve: selectedTopic.isResolve,
              comments: messages,
            })
          }
        }
      })

    const subscriptionEditTopic = editTopicService.reload().subscribe((val) => {
      if (isShowTooltip && selectedTopic !== null) {
        const messages = [...selectedTopic.comments]
        const index = messages.findIndex((x) => x.id === val.id)

        if (selectedTopic.topicId === val.id && index > -1) {
          messages[index].message = val.message
          messages[index].dateCreated = val.dateCreated

          setSelectedTopic({
            topicId: val.id,
            isResolve: selectedTopic.isResolve,
            comments: messages,
          })
        }
      }
    })

    const subscriptionDeleteTopic = deleteTopicService
      .reload()
      .subscribe((val) => {
        if (isShowTooltip && selectedTopic !== null) {
          if (selectedTopic.topicId === val.id) {
            setSelectedTopic(null)
            setIsShowTooltip(false)
          }
        }
      })

    const subscriptionAnnotationResolve = annotationResolveService
      .reload()
      .subscribe((val) => {
        if (isShowTooltip && selectedTopic !== null) {
          if (selectedTopic.topicId === val.topicId) {
            setSelectedTopic({
              topicId: val.topicId,
              isResolve: val.isResolve,
              comments: selectedTopic.comments,
            })
          }
        }
      })

    return () => {
      subscriptionNewComment.unsubscribe()
      subscriptionEditComment.unsubscribe()
      subscriptionDeleteComment.unsubscribe()
      subscriptionEditTopic.unsubscribe()
      subscriptionDeleteTopic.unsubscribe()
      subscriptionAnnotationResolve.unsubscribe()
    }
  }, [isShowTooltip, selectedTopic])

  // set versions
  useEffect(() => {
    if (listVersion.length > 0) {
      let newVersions = {
        internal: listVersion.filter(
          (v) => v.isInternal || (!v.isInternal && v.isInternalApproved)
        ),
        public: listVersion.filter((v) => !v.isInternal),
      }

      if (newVersions.internal.length < 2) {
        newVersions.internal = newVersions.internal.map((x) => {
          x.disabledDelete = true
          return x
        })
      } else {
        newVersions.internal = newVersions.internal.map((x) => {
          x.disabledDelete = false
          return x
        })
      }

      if (newVersions.public.length < 2) {
        newVersions.public = newVersions.public.map((x) => {
          x.disabledDelete = true
          return x
        })
      } else {
        newVersions.public = newVersions.public.map((x) => {
          x.disabledDelete = false
          return x
        })
      }

      setVersions(newVersions)
    }
  }, [listVersion])

  // load versions
  useEffect(() => {
    if (isShowPublicComment) {
      if (
        versions.public.length > 0 &&
        versions.public[selectedPublicVersionIndex]
      ) {
        setTypeDisplay(versions.public[selectedPublicVersionIndex].fileType)
        setUrl(versions.public[selectedPublicVersionIndex].url)
        setSelectedVersion(versions.public[selectedPublicVersionIndex])
        setPdfAnnotationModule(
          versions.public[selectedPublicVersionIndex].pdfAnnotationModule
        )
        if (
          versions.public[selectedPublicVersionIndex].fileType ===
          typeDisplayAnnotation.UNSUPPORT
        ) {
          setIsLoadingFile(false)
        }
      }
    } else {
      if (
        versions.internal.length > 0 &&
        versions.internal[selectedInternalVersionIndex]
      ) {
        setTypeDisplay(versions.internal[selectedInternalVersionIndex].fileType)
        setUrl(versions.internal[selectedInternalVersionIndex].url)
        setSelectedVersion(versions.internal[selectedInternalVersionIndex])
        setPdfAnnotationModule(
          versions.internal[selectedInternalVersionIndex].pdfAnnotationModule
        )
        if (
          versions.internal[selectedInternalVersionIndex].fileType ===
          typeDisplayAnnotation.UNSUPPORT
        ) {
          setIsLoadingFile(false)
        }
      }
    }
  }, [
    versions,
    selectedInternalVersionIndex,
    selectedPublicVersionIndex,
    isShowPublicComment,
  ])

  const updateSelectedTopicByAttrId = useCallback(
    (attrId = '') => {
      let dataAnnotationComment = data.public

      if (!isShowPublicComment) {
        dataAnnotationComment = data.internal
      }

      if (attrId !== '' && dataAnnotationComment.length > 0) {
        const index = dataAnnotationComment.findIndex((x) => {
          const annotate = JSON.parse(x.annotation)
          return annotate.attrs.id === attrId
        })

        if (index > -1) {
          const topic = {
            id: dataAnnotationComment[index].id,
            message: dataAnnotationComment[index].message,
            user: dataAnnotationComment[index].sender,
            isTopic: true,
            dateCreated: dataAnnotationComment[index].dateCreated,
          }
          const messages = [...dataAnnotationComment[index].comments]
          messages.forEach((m) => (m.isTopic = false))
          messages.splice(0, 0, topic)

          setSelectedTopic({
            topicId: dataAnnotationComment[index].id,
            isResolve: dataAnnotationComment[index].isResolve,
            comments: messages,
          })
        } else {
          // reset tooltip previous comment
          setSelectedTopic(null)
          setIsShowTooltip(false)
        }
      } else {
        // reset tooltip previous comment
        setSelectedTopic(null)
        setIsShowTooltip(false)
      }
    },
    [data.internal, data.public, isShowPublicComment]
  )

  const handleSelectedCommentTooltip = useCallback(
    (attrId = '', pos = { x: 0, y: 0 }, annotation = null) => {
      updateSelectedTopicByAttrId(attrId)
      setTooltipPosition(pos)
      setIsShowTooltip(true)
      setIsCloseTooltipEvent(false)
      setSelectedAttrId(attrId)

      if (annotation !== null) {
        setSelectedAnnotation({
          className: annotation.className,
          attrs: annotation.attrs,
          scale: annotation.scale,
          page: annotation.page,
          unsavedComment: annotation.unsavedComment,
        })
      }
    },
    [updateSelectedTopicByAttrId]
  )

  // v0.2
  const centerScreenPdfByShape = useCallback(
    (shape) => {
      const shapeAttrs = shape.attrs
      const className = shape.className
      const viewerContainerEl = pdfViewerWrappState.current.querySelector(
        '.pdf-viewer'
      )
      let newPos = { x: 0, y: 0 }
      const elPages = viewerContainerEl.querySelectorAll('.page')

      if (elPages.length > 0) {
        const groupAttrs = shapeAttrs.groupAttrs
        const indexPage = parseInt(groupAttrs.name.replace('group-', '')) - 1
        const rectPage = elPages[indexPage].getBoundingClientRect()

        let scrollLeft = null
        let scrollTop = null

        if (className === shapeClassname.CIRCLE) {
          const pos = convertToScreenPosition(shapeAttrs, rectPage)
          scrollLeft =
            pos.x +
            pdfViewerWrappState.current.scrollLeft -
            window.innerWidth / 2
          scrollTop =
            pos.y +
            pdfViewerWrappState.current.scrollTop -
            window.innerHeight / 2
        } else if (className === shapeClassname.RECT) {
          const posCenterRect = {
            x: shapeAttrs.x + shapeAttrs.width / 2,
            y: shapeAttrs.y + shapeAttrs.height / 2,
          }
          const pos = convertToScreenPosition(posCenterRect, rectPage)
          scrollLeft =
            pos.x +
            pdfViewerWrappState.current.scrollLeft -
            window.innerWidth / 2
          scrollTop =
            pos.y +
            pdfViewerWrappState.current.scrollTop -
            window.innerHeight / 2
        } else if (className === shapeClassname.ARROW) {
          const posCenterArrow = {
            x: shapeAttrs.x + shapeAttrs.points[2] / 2,
            y: shapeAttrs.y + shapeAttrs.points[3] / 2,
          }
          const pos = convertToScreenPosition(posCenterArrow, rectPage)
          scrollLeft =
            pos.x +
            pdfViewerWrappState.current.scrollLeft -
            window.innerWidth / 2
          scrollTop =
            pos.y +
            pdfViewerWrappState.current.scrollTop -
            window.innerHeight / 2
        } else if (className === shapeClassname.LINE) {
          const posCenterLine = {
            x: shapeAttrs.points[0],
            y: shapeAttrs.points[1],
          }
          const pos = convertToScreenPosition(posCenterLine, rectPage)
          scrollLeft =
            pos.x +
            pdfViewerWrappState.current.scrollLeft -
            window.innerWidth / 2
          scrollTop =
            pos.y +
            pdfViewerWrappState.current.scrollTop -
            window.innerHeight / 2
        }

        pdfViewerWrappState.current.scrollLeft = scrollLeft
        pdfViewerWrappState.current.scrollTop = scrollTop

        const viewerContainerClientRect = viewerContainerEl.getBoundingClientRect()

        newPos = {
          x: viewerContainerClientRect.x,
          y: viewerContainerClientRect.y,
        }
      }

      return newPos
    },
    [pdfViewerWrappState]
  )

  // v0.2
  const centerScreenImageByShape = useCallback((shape) => {
    const shapeAttrs = shape.attrs
    const className = shape.className
    const el = document.querySelector('.img-viewer')
    const elImg = el.querySelector('img')
    const rectImg = elImg.getBoundingClientRect()

    let newPos = { x: 0, y: 0 }

    if (className === shapeClassname.CIRCLE) {
      const pos = convertToScreenPosition(shapeAttrs, rectImg)
      newPos = {
        x: 0 - pos.x + rectImg.x + window.innerWidth / 2,
        y: 0 - pos.y + rectImg.y + window.innerHeight / 2,
      }
    } else if (className === shapeClassname.RECT) {
      const posCenterRect = {
        x: shapeAttrs.x + shapeAttrs.width / 2,
        y: shapeAttrs.y + shapeAttrs.height / 2,
      }
      const pos = convertToScreenPosition(posCenterRect, rectImg)
      newPos = {
        x: 0 - pos.x + rectImg.x + window.innerWidth / 2,
        y: 0 - pos.y + rectImg.y + window.innerHeight / 2,
      }
    } else if (className === shapeClassname.ARROW) {
      const posCenterArrow = {
        x: shapeAttrs.x + shapeAttrs.points[2] / 2,
        y: shapeAttrs.y + shapeAttrs.points[3] / 2,
      }
      const pos = convertToScreenPosition(posCenterArrow, rectImg)
      newPos = {
        x: 0 - pos.x + rectImg.x + window.innerWidth / 2,
        y: 0 - pos.y + rectImg.y + window.innerHeight / 2,
      }
    } else if (className === shapeClassname.LINE) {
      const posCenterLine = {
        x: shapeAttrs.points[0],
        y: shapeAttrs.points[1],
      }
      const pos = convertToScreenPosition(posCenterLine, rectImg)
      newPos = {
        x: 0 - pos.x + rectImg.x + window.innerWidth / 2,
        y: 0 - pos.y + rectImg.y + window.innerHeight / 2,
      }
    }

    setImageBoundRect((v) =>
      update(v, {
        x: { $set: newPos.x },
        y: { $set: newPos.y },
      })
    )

    return newPos
  }, [])

  const updatePositionCenterScreenById = useCallback(
    (attrId) => {
      let pos = { x: 0, y: 0 }

      // centering object shape
      if (typeDisplay === typeDisplayAnnotation.PDF) {
        const shapeIndex = canvasObjects.findIndex((x) => x.attrs.id === attrId)
        if (shapeIndex > -1) {
          const shapeAttrs = canvasObjects[shapeIndex].attrs

          if (shapeAttrs.version && shapeAttrs.version === 0.2) {
            return centerScreenPdfByShape(canvasObjects[shapeIndex])
          } else {
            let scrollTop = null
            let scrollLeft = null

            if (canvasObjects[shapeIndex].className === shapeClassname.ARROW) {
              scrollTop = shapeAttrs.y * imageBoundRect.scale
              scrollLeft = shapeAttrs.x * imageBoundRect.scale
            } else if (
              canvasObjects[shapeIndex].className === shapeClassname.LINE
            ) {
              scrollTop = shapeAttrs.points[1] * imageBoundRect.scale
              scrollLeft = shapeAttrs.points[0] * imageBoundRect.scale
            } else {
              scrollTop = shapeAttrs.y * imageBoundRect.scale
              scrollLeft = shapeAttrs.x * imageBoundRect.scale
            }

            pdfViewerWrappState.current.scrollLeft = scrollLeft
            pdfViewerWrappState.current.scrollTop = scrollTop

            const viewerContainerEl = pdfViewerWrappState.current.querySelector(
              '.pdf-viewer'
            )
            const viewerContainerClientRect = viewerContainerEl.getBoundingClientRect()

            pos = {
              x: viewerContainerClientRect.x,
              y: viewerContainerClientRect.y,
            }
          }
        }
      } else {
        const shapeIndex = canvasObjects.findIndex((x) => x.attrs.id === attrId)
        if (shapeIndex > -1) {
          const shapeAttrs = canvasObjects[shapeIndex].attrs

          if (shapeAttrs.version && shapeAttrs.version === 0.2) {
            return centerScreenImageByShape(canvasObjects[shapeIndex])
          } else {
            /**
             * @deprecated
             */

            let top = null
            let left = null

            if (canvasObjects[shapeIndex].className === shapeClassname.ARROW) {
              top =
                0 - shapeAttrs.y * imageBoundRect.scale + window.innerHeight / 2
              left =
                0 - shapeAttrs.x * imageBoundRect.scale + window.innerWidth / 2
            } else if (
              canvasObjects[shapeIndex].className === shapeClassname.LINE
            ) {
              top =
                0 -
                shapeAttrs.points[1] * imageBoundRect.scale +
                window.innerHeight / 2
              left =
                0 -
                shapeAttrs.points[0] * imageBoundRect.scale +
                window.innerWidth / 2
            } else {
              top =
                0 - shapeAttrs.y * imageBoundRect.scale + window.innerHeight / 2
              left =
                0 - shapeAttrs.x * imageBoundRect.scale + window.innerWidth / 2
            }

            setImageBoundRect((v) =>
              update(v, {
                x: { $set: left },
                y: { $set: top },
              })
            )

            pos = { x: left, y: top }
          }
        }
      }

      return pos
    },
    [
      canvasObjects,
      centerScreenImageByShape,
      centerScreenPdfByShape,
      imageBoundRect.scale,
      pdfViewerWrappState,
      typeDisplay,
    ]
  )

  //[VIEW COMMENT]
  const getGroupAttrs = useCallback(
    (shapeAttrs) => {
      if (typeDisplay === typeDisplayAnnotation.PDF) {
        const groupAttrs = shapeAttrs.groupAttrs
        const viewerContainerEl = pdfViewerWrappState.current.querySelector(
          '.pdf-viewer'
        )
        const elPages = viewerContainerEl.querySelectorAll('.page')

        if (elPages.length > 0) {
          const indexPage = parseInt(groupAttrs.name.replace('group-', '')) - 1
          const rectPage = elPages[indexPage].getBoundingClientRect()

          return rectPage
        }

        return null
      }

      const el = document.querySelector('.img-viewer')
      const elImg = el.querySelector('img')

      if (elImg) {
        const rectImg = elImg.getBoundingClientRect()

        return rectImg
      }

      return null
    },
    [pdfViewerWrappState, typeDisplay]
  )

  //[VIEW COMMENT]
  const showCommentToolTip = useCallback(
    (shape) => {
      setTimeout(() => {
        const shapeAttrs = shape.attrs
        const groupAttrs = getGroupAttrs(shapeAttrs)

        if (groupAttrs) {
          let pos = {
            x: shapeAttrs.x,
            y: shapeAttrs.y,
          }

          if (shape.className === shapeClassname.LINE) {
            pos = {
              x: shapeAttrs.points[0],
              y: shapeAttrs.points[1],
            }
          }

          const posScreen = convertToScreenPosition(pos, groupAttrs)
          handleSelectedCommentTooltip(shapeAttrs.id, posScreen)
        }
      }, 350)
    },
    [getGroupAttrs, handleSelectedCommentTooltip]
  )

  //[VIEW COMMENT]
  const openViewCommentByAnnotation = useCallback(
    (comment) => {
      const annotation = JSON.parse(comment.annotation)
      const attrs = annotation.attrs
      const attrId = attrs.id

      setIsCloseTooltipEvent(false)
      setIsShowTooltip(false)
      const posLayout = updatePositionCenterScreenById(attrId)

      if (attrs.version && attrs.version === 0.2) {
        showCommentToolTip(annotation)
      } else {
        /** @deprecated */
        setTimeout(() => {
          const pos = revertToCanvasDimentionPosition(
            { x: attrs.x, y: attrs.y },
            { scale: imageBoundRect.scale, ...posLayout }
          )
          handleSelectedCommentTooltip(attrId, pos)
        }, 350)
      }
    },
    [
      handleSelectedCommentTooltip,
      imageBoundRect.scale,
      showCommentToolTip,
      updatePositionCenterScreenById,
    ]
  )

  //[VIEW COMMENT]
  const openViewComment = useCallback(
    (commentId) => {
      const dataAnnotationComment = [...data.public, ...data.internal]

      if (dataAnnotationComment.length > 0) {
        let idx = dataAnnotationComment.findIndex((x) => x.id === commentId)

        if (idx > -1) {
          // found topic
          openViewCommentByAnnotation(dataAnnotationComment[idx])
        } else {
          // find comment of topic
          idx = dataAnnotationComment.findIndex((x) => {
            if (x.comments.length > 0) {
              return x.comments.findIndex((i) => i.id === commentId) > -1
            }

            return false
          })

          if (idx > -1) {
            openViewCommentByAnnotation(dataAnnotationComment[idx])
          } else {
            if (!toastMessageWarningOpenView) {
              toastMessageWarningOpenView = toastCenter.message(
                'Comment Was Deleted',
                'The comment you are trying to access is not accessible anymore. The attachment or the comment might be removed.',
                toastMessageType.WARNING
              )
            }
          }
        }
      } else {
        if (!toastMessageWarningOpenView) {
          toastMessageWarningOpenView = toastCenter.message(
            'Comment Was Deleted',
            'The comment you are trying to access is not accessible anymore. The attachment or the comment might be removed.',
            toastMessageType.WARNING
          )
        }
      }
      timeoutOpenViewComment = setTimeout(() => {
        setIsOpenViewComment(false)
      }, 1200)
    },
    [data.internal, data.public, openViewCommentByAnnotation]
  )

  //[VIEW COMMENT]
  // set version url by open view comment
  useEffect(() => {
    if (!isLoadingFile && isOpenViewComment && queryPublic && queryVersionId) {
      setIsShowPublicComment(queryPublic)

      const idx = versions.public.findIndex((x) => x.id === queryVersionId)
      if (idx > -1) {
        setSelectedPublicVersionIndex(idx)
        setIsReadOnly(versions.public[idx].isDisabledAnnotation)
      }
      setIsShapeShowed(!isLoadingFile)
    }
    if (!isLoadingFile && isOpenViewComment && !queryPublic && queryVersionId) {
      const idx = versions.internal.findIndex((x) => x.id === queryVersionId)
      if (idx > -1) {
        setSelectedInternalVersionIndex(idx)
        setIsReadOnly(versions.internal[idx].isDisabledInternalAnnotation)
      }
      setIsShapeShowed(!isLoadingFile)
    }
  }, [
    isLoadingFile,
    isOpenViewComment,
    queryPublic,
    queryVersionId,
    versions.internal,
    versions.public,
  ])

  //[VIEW COMMENT]
  const handleAnnotationRendered = useCallback(() => {
    if (!isLoadingFile && queryCommentId && isOpenViewComment) {
      if (timeoutOpenViewComment) {
        clearTimeout(timeoutOpenViewComment)
      }

      openViewComment(queryCommentId)
    }
  }, [isLoadingFile, isOpenViewComment, openViewComment, queryCommentId])

  useEventListener('annotationFileReady', handleAnnotationRendered, document)
  //#endregion

  //#region Functions
  const handleSelectedComment = (attrId) => {
    setIsCloseTooltipEvent(false)
    setIsShowTooltip(false)
    setSelectedAttrId(attrId)
    updateSelectedTopicByAttrId(attrId)
    updatePositionCenterScreenById(attrId)
  }

  const addShadowDrop = useCallback(
    (groupName) => {
      if (groupName !== '') {
        if (typeDisplay === typeDisplayAnnotation.PDF) {
          const containerPdfViewer = document.querySelector('.viewer-container')

          if (containerPdfViewer) {
            const pagesEl = containerPdfViewer.querySelectorAll('.page')
            const currentPageNumber = groupName.replace('group-', '')
            pagesEl.forEach((el) => {
              if (
                parseInt(el.dataset.pageNumber) !==
                  parseInt(currentPageNumber) &&
                !el.querySelector('.pdf-viewer__shadow')
              ) {
                const shadow = document.createElement('div')
                shadow.classList.add('pdf-viewer__shadow')
                el.prepend(shadow)
              }
            })
          }
        }
      }
    },
    [typeDisplay]
  )

  const clearShadowDrop = useCallback(() => {
    if (typeDisplay === typeDisplayAnnotation.PDF && pdfViewerState) {
      const containerPdfViewer = pdfViewerState.container
      const pagesEl = containerPdfViewer.querySelectorAll('.page')
      pagesEl.forEach((el) => {
        const shadow = el.querySelector('.pdf-viewer__shadow')
        if (shadow) {
          shadow.remove()
        }
      })
    }
  }, [pdfViewerState, typeDisplay])

  const handleScalePDF = useCallback(
    (scale) => {
      if (pdfViewerState) {
        const containerPdfViewer = pdfViewerState.container

        if (containerPdfViewer) {
          const pdfViewerRef = containerPdfViewer.querySelector('#viewer')
          const pdfViewerWrappStateELement = pdfViewerWrappState.current

          if (
            pdfViewerRef &&
            pdfViewerRef.querySelector('.page') !== null &&
            pdfViewerWrappStateELement
          ) {
            const paddingTop = window.innerHeight / 2 - heightHeader
            const lastScrollTop = pdfViewerWrappStateELement.scrollTop
            const currentScroll = Math.abs(lastScrollTop - 200)
            const lastHeightPage = parseInt(
              pdfViewerRef.querySelector('.page').offsetHeight
            )
            const lastActivePage = Math.ceil(
              currentScroll / (lastHeightPage + 30)
            )
            const lastScale = pdfViewerState.currentScale
            const lastMargin = baseMarginPage * lastScale
            const newMargin = baseMarginPage * scale
            const cleanTopPosition =
              lastScrollTop - paddingTop - (lastActivePage - 1) * lastMargin

            pdfViewerState.currentScaleValue = scale

            addShadowDrop(activeDrawingArea)

            /**
             * @deprecated at @version 0.2
             * this for old annotation canvas
             */
            setMarginPage(newMargin * scale)

            // zooming stick to current page
            if (pdfViewerWrappState) {
              if (!pdfViewerRef.querySelector('.page')) {
                return
              }

              const scrollLeft =
                containerPdfViewer.offsetWidth / 2 - window.innerWidth / 2

              pdfViewerWrappState.current.scrollLeft = scrollLeft

              /**
               * pdfViewerState.pagesCount is current page
               */
              if (pdfViewerState && pdfViewerState.pagesCount === 1) {
                const heightPage = pdfViewerRef.querySelector('.page')
                  .clientHeight
                centeringPDFByHeight(pdfViewerWrappState.current, heightPage)
              } else {
                const newHeightPage = parseInt(
                  pdfViewerRef.querySelector('.page').offsetHeight
                )
                const deviderScale =
                  lastScale < pdfViewerState.currentScale
                    ? lastHeightPage
                    : lastHeightPage
                const calcScale =
                  Math.abs(lastHeightPage - newHeightPage) / deviderScale
                const scrollTopScaled =
                  lastScale < pdfViewerState.currentScale
                    ? cleanTopPosition * calcScale + cleanTopPosition
                    : cleanTopPosition - cleanTopPosition * calcScale
                const scrollTop =
                  scrollTopScaled +
                  paddingTop +
                  (lastActivePage - 1) * newMargin
                pdfViewerWrappState.current.scrollTop = scrollTop
              }
            }

            if (
              selectedVersion &&
              selectedVersion.pdfAnnotationModule !==
                pdfAnnotationModules.pdfTools
            ) {
              // Update scale factor adobe viewer
              const adobeViewerContainer = containerPdfViewer.querySelector(
                '#adobe-viewer-container'
              )
              const scaleFactor = getComputedStyle(
                pdfViewerRef
              ).getPropertyValue('--scale-factor')
              adobeViewerContainer.style.setProperty(
                '--scale-factor',
                scaleFactor
              )

              // Update width & height adobe viewer
              const adobeViewer = adobeViewerContainer.querySelector(
                '#adobe-viewer'
              )
              const heightPage = getComputedStyle(
                pdfViewerRef.querySelector('.page')
              ).getPropertyValue('height')
              const widthPage = getComputedStyle(
                pdfViewerRef.querySelector('.page')
              ).getPropertyValue('width')
              adobeViewer.style.setProperty('height', heightPage)
              adobeViewer.style.setProperty('width', widthPage)
            }
          }
        }
      }
    },
    [
      activeDrawingArea,
      addShadowDrop,
      pdfViewerState,
      pdfViewerWrappState,
      selectedVersion,
    ]
  )

  const handleScaleImg = useCallback(
    (scale) => {
      if (imgRefState && imgRefState.current) {
        const naturalWidth = imgRefState.current.naturalWidth

        const left = window.innerWidth / 2 - (naturalWidth * scale) / 2
        // const top = (window.innerHeight / 4);
        const top = heightHeader + marginHeader

        setImageBoundRect((v) =>
          update(v, {
            x: { $set: left },
            y: { $set: top },
            width: { $set: v.naturalWidth * scale },
            height: { $set: v.naturalHeight * scale },
            scale: { $set: scale },
          })
        )
      }
      setIsLoadingFile(false)
    },
    [imgRefState]
  )

  const handleChangeScale = useCallback(
    (scale) => {
      if (typeDisplay === typeDisplayAnnotation.PDF) {
        handleScalePDF(scale)
      } else {
        handleScaleImg(scale)
      }
    },
    [handleScaleImg, handleScalePDF, typeDisplay]
  )

  //#region resize pdf
  const handleResizePDF = useCallback(
    (scale) => {
      if (pdfViewerState) {
        const containerPdfViewer = pdfViewerState.container

        if (containerPdfViewer) {
          const pdfViewerRef = containerPdfViewer.querySelector('#viewer')
          const pdfViewerWrappStateELement = pdfViewerWrappState.current

          if (
            pdfViewerRef &&
            pdfViewerRef.querySelector('.page') !== null &&
            pdfViewerWrappStateELement
          ) {
            const paddingTop = window.innerHeight / 2 - heightHeader
            const lastScrollTop = pdfViewerWrappStateELement.scrollTop
            const currentScroll = Math.abs(lastScrollTop - 200)
            const lastHeightPage = parseInt(
              pdfViewerRef.querySelector('.page').offsetHeight
            )
            const lastActivePage = Math.ceil(
              currentScroll / (lastHeightPage + 30)
            )
            const lastScale = pdfViewerState.currentScale
            const lastMargin = baseMarginPage * lastScale
            const newMargin = baseMarginPage * scale
            const cleanTopPosition =
              lastScrollTop - paddingTop - (lastActivePage - 1) * lastMargin

            // zooming stick to current page
            if (pdfViewerWrappState) {
              if (!pdfViewerRef.querySelector('.page')) {
                return
              }

              const scrollLeft =
                containerPdfViewer.offsetWidth / 2 - window.innerWidth / 2
              const newHeightPage = parseInt(
                pdfViewerRef.querySelector('.page').offsetHeight
              )
              const deviderScale =
                lastScale < pdfViewerState.currentScale
                  ? lastHeightPage
                  : lastHeightPage
              const calcScale =
                Math.abs(lastHeightPage - newHeightPage) / deviderScale
              const scrollTopScaled =
                lastScale < pdfViewerState.currentScale
                  ? cleanTopPosition * calcScale + cleanTopPosition
                  : cleanTopPosition - cleanTopPosition * calcScale
              const scrollTop =
                scrollTopScaled + paddingTop + (lastActivePage - 1) * newMargin

              pdfViewerWrappStateELement.scrollLeft = scrollLeft

              // todo FIXME: buggy on resize height
              pdfViewerWrappStateELement.scrollTop = scrollTop
            }
          }
        }
      }
    },
    [pdfViewerState, pdfViewerWrappState]
  )
  //#endregion

  const handleResize = useCallback(() => {
    if (typeDisplay === typeDisplayAnnotation.PDF) {
      handleResizePDF(imageBoundRect.scale)
    } else {
      handleScaleImg(imageBoundRect.scale)
    }
  }, [handleResizePDF, handleScaleImg, imageBoundRect.scale, typeDisplay])

  useEventListener('resize', handleResize, window)

  const handleNavLeft = useCallback(() => {
    if (typeof onNavPrev === 'function') {
      setIsLoadingFile(true)
      onNavPrev()
    }
  }, [onNavPrev])

  const handleNavRight = useCallback(() => {
    if (typeof onNavNext === 'function') {
      setIsLoadingFile(true)
      onNavNext()
    }
  }, [onNavNext])

  const clearActiveDrawingArea = useCallback(
    (isForceClear = false) => {
      if (!isForceClear) {
        const unsavedObjects = canvasObjects.filter((x) => x.isUnsaved)

        if (
          unsavedObjects.length === 1 &&
          selectedAnnotation &&
          selectedAnnotation.attrs.id === unsavedObjects[0].attrs.id
        ) {
          setActiveDrawingArea('')
          clearShadowDrop()
        }
      } else {
        // force clear when save topic comment
        setActiveDrawingArea('')
        clearShadowDrop()
      }
    },
    [canvasObjects, clearShadowDrop, selectedAnnotation]
  )

  const updateActiveDrawingArea = useCallback(
    (groupName) => {
      addShadowDrop(groupName)
      setActiveDrawingArea(groupName)
    },
    [addShadowDrop]
  )

  // show tooltip on unsaved topic and centering
  const handleHighlightUnsaved = useCallback(() => {
    const unsavedIndex = canvasObjects.findIndex((x) => x.isUnsaved)
    if (unsavedIndex > -1) {
      const objCanvas = canvasObjects[unsavedIndex]
      let attrs = objCanvas.attrs
      const className = objCanvas.className

      if (className === shapeClassname.LINE) {
        attrs = {
          ...objCanvas.attrs,
          x: objCanvas.attrs.points[0],
          y: objCanvas.attrs.points[1],
        }
      }

      updatePositionCenterScreenById(attrs.id)

      if (attrs.version && attrs.version === 0.2) {
        if (typeDisplay === typeDisplayAnnotation.PDF) {
          const viewerContainerEl = pdfViewerWrappState.current.querySelector(
            '.pdf-viewer'
          )
          const elPages = viewerContainerEl.querySelectorAll('.page')
          const groupAttrs = attrs.groupAttrs
          const indexPage = parseInt(groupAttrs.name.replace('group-', '')) - 1
          const rectPage = elPages[indexPage].getBoundingClientRect()
          const posScreen = convertToScreenPosition(attrs, rectPage)

          handleSelectedCommentTooltip(attrs.id, posScreen, objCanvas)
        } else {
          setIsShowTooltip(false)

          // too fast
          setTimeout(() => {
            const el = document.querySelector('.img-viewer')
            const elImg = el.querySelector('img')
            const rectImg = elImg.getBoundingClientRect()
            const posScreen = convertToScreenPosition(attrs, rectImg)

            handleSelectedCommentTooltip(attrs.id, posScreen, objCanvas)
          }, 350)
        }
      } else {
        const attrsRevertedDimension = revertToCanvasDimentionPosition(
          attrs,
          imageBoundRect
        )
        handleSelectedCommentTooltip(
          attrs.id,
          attrsRevertedDimension,
          objCanvas
        )
      }
    }
  }, [
    canvasObjects,
    handleSelectedCommentTooltip,
    imageBoundRect,
    pdfViewerWrappState,
    typeDisplay,
    updatePositionCenterScreenById,
  ])

  const clearUnsavedTopic = useCallback(() => {
    const unsavedObjects = canvasObjects.filter((x) => x.isUnsaved)

    if (
      unsavedObjects.length === 1 &&
      selectedAnnotation &&
      selectedAnnotation.attrs.id === unsavedObjects[0].attrs.id
    ) {
      setHasUnsavedComment(false)
    }

    const idxCurrentUnsavedObject = canvasObjects.findIndex(
      (x) => x.isUnsaved && x.attrs.id === selectedAnnotation.attrs.id
    )

    // remove unsaved object
    if (idxCurrentUnsavedObject > -1) {
      setCanvasObjects((x) =>
        update(x, {
          $splice: [[idxCurrentUnsavedObject, 1]],
        })
      )
    }
  }, [canvasObjects, selectedAnnotation])

  const handleTogglePanelVersion = () => {
    setIsShowVersionPanel(!isShowVersionPanel)
  }

  const handleTogglePanelComment = () => {
    setIsShowCommentPanel(!isShowCommentPanel)
  }
  //#endregion

  const downloadMultiPagesToPDF = (filename, dataURLs) => {
    // const watermarkImg = isDark ? watermarkWhite : watermarkBlack
    filename = `${filename}_proof`
    const watermarkUrl =
      downloadWithWatermark && downloadWithWatermark.watermarkFile
        ? downloadWithWatermark.watermarkFile
        : ''
    const orientation =
      getOrientation(dataURLs[0].width, dataURLs[0].height) ===
      orientationStruct.LANDSCAPE
        ? 'l'
        : 'p'

    const pdf = new jsPDF({
      orientation: orientation,
      unit: 'px',
      compress: true,
      format: [dataURLs[0].width, dataURLs[0].height],
    })

    if (
      downloadWithWatermark &&
      downloadWithWatermark.isDownloadWithWatermark &&
      watermarkUrl
    ) {
      Axios.get(watermarkUrl, { responseType: 'blob' })
        .then((response) => {
          blobUtil
            .blobToDataURL(response.data)
            .then((wmDataURL) => {
              // is backdrop
              if (
                downloadWithWatermark &&
                downloadWithWatermark.isDarkBackground
              ) {
                backdrop().then((bg) => {
                  for (let i = 0; i < dataURLs.length; i++) {
                    const pageData = dataURLs[i]
                    pdf.addImage(
                      pageData.dataURL,
                      'JPEG',
                      0,
                      0,
                      pageData.width,
                      pageData.height
                    )
                    pdf.addImage(
                      bg,
                      'PNG',
                      0,
                      0,
                      pageData.width,
                      pageData.height
                    )

                    generateWatermark(
                      pdf,
                      wmDataURL,
                      pageData.width,
                      pageData.height
                    )
                    if (i < dataURLs.length - 1) {
                      pdf.addPage()
                    }
                  }
                  setIsDownloadFile(false)
                  pdf.save(`${filename}.pdf`)
                })
              } else {
                for (let i = 0; i < dataURLs.length; i++) {
                  const pageData = dataURLs[i]
                  pdf.addImage(
                    pageData.dataURL,
                    'JPEG',
                    0,
                    0,
                    pageData.width,
                    pageData.height
                  )
                  generateWatermark(
                    pdf,
                    wmDataURL,
                    pageData.width,
                    pageData.height
                  )
                  if (i < dataURLs.length - 1) {
                    const nexPageData = dataURLs[i + 1]
                    const pageOrientation =
                      getOrientation(nexPageData.width, nexPageData.height) ===
                      orientationStruct.LANDSCAPE
                        ? 'l'
                        : 'p'
                    pdf.addPage(
                      [nexPageData.width, nexPageData.height],
                      pageOrientation
                    )
                  }
                }
                setIsDownloadFile(false)
                pdf.save(`${filename}.pdf`)
              }
            })
            .catch((err) => {
              alert('Oops, something went wrong download pdf')
              setIsDownloadFile(false)
            })
        })
        .catch((err) => {
          alert('Oops, something went wrong download pdf')
          setIsDownloadFile(false)
        })
    } else {
      for (let i = 0; i < dataURLs.length; i++) {
        const pageData = dataURLs[i]
        pdf.addImage(
          pageData.dataURL,
          'JPEG',
          0,
          0,
          pageData.width,
          pageData.height
        )
        if (i < dataURLs.length - 1) {
          const nexPageData = dataURLs[i + 1]
          const pageOrientation =
            getOrientation(nexPageData.width, nexPageData.height) ===
            orientationStruct.LANDSCAPE
              ? 'l'
              : 'p'
          pdf.addPage([nexPageData.width, nexPageData.height], pageOrientation)
        }
      }
      setIsDownloadFile(false)
      pdf.save(`${filename}.pdf`)
    }
  }

  const downloadToPDF = (filename, dataURL, width, height, isDark = false) => {
    // const watermarkImg = isDark ? watermarkWhite : watermarkBlack
    filename = `${filename}_proof`
    const watermarkUrl =
      downloadWithWatermark && downloadWithWatermark.watermarkFile
        ? downloadWithWatermark.watermarkFile
        : ''

    const orientation =
      getOrientation(width, height) === orientationStruct.LANDSCAPE ? 'l' : 'p'

    const pdf = new jsPDF({
      orientation: orientation,
      unit: 'px',
      compress: true,
      format: [width, height],
    })

    pdf.addImage(dataURL, 'JPEG', 0, 0, width, height)

    if (
      downloadWithWatermark &&
      downloadWithWatermark.isDownloadWithWatermark &&
      watermarkUrl
    ) {
      Axios.get(watermarkUrl, { responseType: 'blob' })
        .then((response) => {
          blobUtil
            .blobToDataURL(response.data)
            .then((wmDataURL) => {
              // is backdrop
              if (
                downloadWithWatermark &&
                downloadWithWatermark.isDarkBackground
              ) {
                backdrop().then((bg) => {
                  pdf.addImage(bg, 'PNG', 0, 0, width, height)

                  generateWatermark(pdf, wmDataURL, width, height)

                  pdf.save(`${filename}.pdf`)
                })
              } else {
                generateWatermark(pdf, wmDataURL, width, height)

                pdf.save(`${filename}.pdf`)
              }
            })
            .catch((err) => {
              // error
              console.error(err)
              alert('Oops, something went wrong download pdf')
            })
        })
        .catch((err) => {
          console.error(err)
          alert('Oops, something went wrong download pdf')
        })
    } else {
      pdf.addImage(dataURL, 'JPEG', 0, 0, width, height)
      pdf.save(`${filename}.pdf`)
    }
  }

  const handleDownloadFile = async () => {
    let currentVersion = versions.internal[selectedInternalVersionIndex]

    if (isShowPublicComment) {
      currentVersion = versions.public[selectedPublicVersionIndex]
    }

    if (typeDisplay === typeDisplayAnnotation.PDF) {
      if (pdfAnnotationModule === pdfAnnotationModules.pdfTools) {
        downloadProofService.emitReload(true)
      } else {
        const viewerContainerEl = pdfViewerWrappState.current.querySelector(
          '.pdf-viewer'
        )
        if (viewerContainerEl) {
          setIsDownloadFile(true)
          let dataURLs = []
          for (let i = 1; i <= totalPagePdf; i++) {
            const currentDivPage = viewerContainerEl.querySelector(
              `.pdf-viewer .page[data-page-number='${i}']`
            )

            if (currentDivPage) {
              const canvasEL = currentDivPage.querySelector('canvas')
              if (canvasEL) {
                const dataURL = canvasEL.toDataURL('image/png')
                const width = currentDivPage.clientWidth
                const height = currentDivPage.clientHeight
                const isDark = await getDark(
                  getImage(canvasEL.toDataURL('image/png'))
                )

                dataURLs.push({
                  dataURL,
                  width,
                  height,
                  isDark,
                })
              }
            }
          }

          const filename = `${currentVersion.fileName
            .split('.')
            .slice(0, -1)
            .join('.')}`

          downloadMultiPagesToPDF(filename, dataURLs)
        }
      }
    } else if (typeDisplay === typeDisplayAnnotation.IMAGE) {
      const imgContainer = document.querySelector('.img-viewer')
      if (imgContainer) {
        setIsDownloadFile(true)
        const img = imgContainer.querySelector('img')

        Axios.get(`${url}?v=${getUnixTime(new Date())}`, {
          responseType: 'blob',
        }).then((x) => {
          setIsDownloadFile(false)
          if (x.status === 200) {
            blobUtil
              .blobToDataURL(x.data)
              .then(function (dataURL) {
                const width = img.width
                const height = img.height
                const filename = `${currentVersion.fileName
                  .split('.')
                  .slice(0, -1)
                  .join('.')}`

                // downloadToPDF(filename, dataURL, width, height)
                getDark(getImage(dataURL)).then((isDark) => {
                  downloadToPDF(filename, dataURL, width, height, isDark)
                })
              })
              .catch(function (err) {
                // error
                setIsDownloadFile(false)
                alert('Oops, something went wrong')
              })
          }
        })
      }
    }
  }

  //#region Context Value
  const versionContextValue = {
    selectedInternalVersionIndex: selectedInternalVersionIndex,
    selectedPublicVersionIndex: selectedPublicVersionIndex,
    onSelectedInternalVersionIndex: setSelectedInternalVersionIndex,
    onSelectedPublicVersionIndex: setSelectedPublicVersionIndex,
    selectedVersion: selectedVersion,
    onSelectedVersion: (version) => {
      setSelectedVersion(version)
      setPdfAnnotationModule(versions.pdfAnnotationModule)
    },
    versions: versions,
    onChangeVersion: onChangeVersion,
    onApproveVersion: onApproveVersion,
    onReviseVersion: onReviseVersion,
    orderProgress: orderProgress,
    attachmentTypeStartFrom: attachmentTypeStartFrom,
    onDeleteAttachmentVersion: onDeleteAttachmentVersion,
  }

  const shapeContextValue = {
    user: currentUser,
    listMentions: listMentions,
    isLoadAnnotation: isLoadAnnotation,
    isShapeShowed: isShapeShowed,
    onIsShapeShowed: setIsShapeShowed,
    selectedAttrId: selectedAttrId,
    onSelectedShape: (attrId) => {
      setIsCloseTooltipEvent(false)
      setIsShowTooltip(false)
      setSelectedAttrId(attrId)
    },
    canvasObjects: canvasObjects,
    updateCanvasObjects: setCanvasObjects,
    isReadOnly: isReadOnly,
    isNewVersionCanvas: isNewVersionCanvas,
    groupCount: totalPagePdf,
    clearActiveDrawingArea: clearActiveDrawingArea,
    clearUnsavedTopic: clearUnsavedTopic,
  }

  const imgContextValue = {
    onImgRef: setImgRefState,
    onChangeScale: handleScaleImg,
  }

  const pdfContextValue = {
    onChangeScale: handleScalePDF,
    pdfViewerState: pdfViewerState,
    onPdfViewerState: setPdfViewerState,
    pdfViewerWrappRef: pdfViewerWrappState,
    updatePdfViewerWrappRef: setPdfViewerWrappState,
    updateLayerPdfScroll: setLayerPdfScroll,
    layerPdfScroll: layerPdfScroll,
    totalPage: totalPagePdf,
    updateTotalPage: setTotalPagePdf,
    baseMarginPage: baseMarginPage,
    marginPage: marginPage,
    activePage: activePagePdf,
    updateActivePage: setActivePagePdf,
    user: currentUser,
  }

  const previewContextValue = {
    url: url,
    typeDisplay: typeDisplay,
    isDragging: isDragging,
    onIsDragging: setIsDragging,
    imageBoundRect: imageBoundRect,
    onImageBoundRectChanged: setImageBoundRect,
    draggedPosition: draggedPosition,
    onDraggedPosition: setDraggedPosition,
    transitionProperty: transitionProperty,
    onTransitionProperty: setTransitionProperty,
    toolboxComponentAction: toolboxAction,
    onToolboxComponentActionChanged: (act) => {
      setIsShowTooltip(false)
      setToolboxAction(act)
    },
    isLoadingFile: isLoadingFile,
    onIsLoadingFile: setIsLoadingFile,
    isLoadingPage: isLoadingPage,
    updatePositionCenterScreenById: updatePositionCenterScreenById,
    onChangeScale: handleChangeScale,
    byteLength: byteLength,
    zoomDetail: zoomDetail,
    updateZoomDetail: setZoomDetail,
    activeDrawingArea: activeDrawingArea,
    updateActiveDrawingArea: updateActiveDrawingArea,
  }

  const tooltipContextValue = {
    onIsCloseTooltipEvent: setIsCloseTooltipEvent,
    isCloseTooltipEvent: isCloseTooltipEvent,
    isShowTooltip: isShowTooltip,
    onShowTooltip: setIsShowTooltip,
    onSelectedTopic: setSelectedTopic,
    selectedTopic: selectedTopic,
    onSelectedTopicByAttrId: (attrId) => {
      updateSelectedTopicByAttrId(attrId)
    },
    tooltipPlace: tooltipPlace,
    onTooltipPlaceChanged: setTooltipPlace,
    tooltipPosition: tooltipPosition,
    onTooltipPositionChanged: setTooltipPosition,
    selectedAnnotation: selectedAnnotation,
    onUpdateUnsavedCommentSelectedAnnotation: (unsavedComment = '') => {
      if (selectedAnnotation !== null) {
        const idx = canvasObjects.findIndex(
          (x) => x.attrs.id === selectedAnnotation.attrs.id
        )
        if (idx > -1) {
          setCanvasObjects((x) =>
            update(x, {
              [idx]: {
                unsavedComment: { $set: unsavedComment },
              },
            })
          )
        }
      }
    },
    onSelectedCommentTooltip: handleSelectedCommentTooltip,
    onHighlightUnsaved: handleHighlightUnsaved,
    onHasUnsavedComment: setHasUnsavedComment,
    hasUnsavedComment: hasUnsavedComment,
  }

  const contextMenuContextValue = {
    isShowcontextMenu: isShowcontextMenu,
    onIsShowcontextMenu: setIsShowcontextMenu,
    positionContextMenu: positionContextMenu,
    onPositionContextMenu: setPositionContextMenu,
  }

  const headerAnnotationContextValue = {
    isShowPublicComment: isShowPublicComment,
    onShowPublicComment: () => {
      if (url !== versions.public[selectedPublicVersionIndex].url) {
        setIsLoadingFile(true)
      }

      setIsShowPublicComment(true)
      onChangeVersion(versions.public[selectedPublicVersionIndex].id, true)
      setCommentThreadPanel(publicComments)
      setIsReadOnly(
        versions.public[selectedPublicVersionIndex].isDisabledAnnotation
      )
      setIsShowTooltip(false)
      setToolboxAction({
        type: shapeClassname.MOUSE,
        handler: toolboxActionHandler.MOUSE_CLICK,
        color: toolboxColorPalette.BLUE,
      })

      setCanvasObjects((_) => {
        return data.public.map((x) => {
          const annotation =
            x.annotation !== '' ? JSON.parse(x.annotation) : null
          return {
            ...annotation,
            isUnsaved: false,
            unsavedComment: '',
          }
        })
      })
    },
    onShowInternalComment: () => {
      if (url !== versions.internal[selectedInternalVersionIndex].url) {
        setIsLoadingFile(true)
      }

      setIsShowPublicComment(false)
      onChangeVersion(versions.internal[selectedInternalVersionIndex].id, false)
      setCommentThreadPanel(internalComments)
      setIsReadOnly(
        versions.internal[selectedInternalVersionIndex]
          .isDisabledInternalAnnotation
      )
      setIsShowTooltip(false)
      setToolboxAction({
        type: shapeClassname.MOUSE,
        handler: toolboxActionHandler.MOUSE_CLICK,
        color: toolboxColorPalette.BLUE,
      })

      if (pdfAnnotationModule !== pdfAnnotationModules.pdfTools) {
        setCanvasObjects((_) => {
          return data.internal.map((x) => {
            const annotation =
              x.annotation !== '' ? JSON.parse(x.annotation) : null
            return {
              ...annotation,
              isUnsaved: false,
              unsavedComment: '',
            }
          })
        })
      }
    },
    isShowCommentPanel: isShowCommentPanel,
    onShowCommentPanel: () => {
      setIsShowCommentPanel(!isShowCommentPanel)
    },
    isShowVersionPanel: isShowVersionPanel,
    onShowVersionPanel: () => setIsShowVersionPanel(!isShowVersionPanel),
    isInternalTabDisabled: isInternalTabDisabled,
    isPublicTabDisabled: isPublicTabDisabled,
  }

  const annotationActionContextValue = {
    onDownloadFile: onDownloadFile,
    onOpenNewTab: onOpenNewTab,
    onCopyFileUrl: onCopyFileUrl,
    onSendComment: onSendComment,
    onSilentUpdate: onSilentUpdate,
    onSaveEditComment: onSaveEditComment,
    onDeleteComment: onDeleteComment,
    onResolveComment: onResolveComment,
    onReopenComment: onReopenComment,
    onClose: onClose,
    annotations: data,
  }

  const loaderContextValue = {
    loadedPercent: loadedPercent,
    onLoadedPercent: setLoadedPercent,
  }

  const reviewerContextValue = {
    ability: ability,
    onActionMakeChange: onActionMakeChange,
    onActionStayInReview: onActionStayInReview,
    onActionPutOnHold: onActionPutOnHold,
    onActionOrderApproved: onActionOrderApproved,
  }
  //#endregion

  return (
    <div
      className={`annotation-wrapper${
        pdfAnnotationModule === pdfAnnotationModules.pdfTools ? 'white-bg' : ''
      }`}
    >
      <LoaderContext.Provider value={loaderContextValue}>
        <VersionContext.Provider value={versionContextValue}>
          <ShapeContext.Provider value={shapeContextValue}>
            <PreviewContext.Provider value={previewContextValue}>
              <TooltipContext.Provider value={tooltipContextValue}>
                <HeaderAnnotationContext.Provider
                  value={headerAnnotationContextValue}
                >
                  <ContextMenuContext.Provider value={contextMenuContextValue}>
                    <AnnotationActionContext.Provider
                      value={annotationActionContextValue}
                    >
                      <ImgContext.Provider value={imgContextValue}>
                        <PdfContext.Provider value={pdfContextValue}>
                          <Loader />
                          <LoaderFile />

                          <ReviewerContext.Provider
                            value={reviewerContextValue}
                          >
                            {pdfAnnotationModule !==
                              pdfAnnotationModules.pdfTools && (
                              <HeaderAnnotation
                                publicCommentCount={publicComments.length}
                                internalCommentCount={internalComments.length}
                                useClose={useClose}
                                useVersion={useVersion}
                                onDownloadFile={handleDownloadFile}
                                isDownloadFile={isDownloadFile}
                              />
                            )}
                          </ReviewerContext.Provider>

                          <AnnotationViewer typeDisplay={typeDisplay} />

                          {!isLoadingPage &&
                            !isLoadingFile &&
                            typeDisplay === typeDisplayAnnotation.UNSUPPORT && (
                              <NotSupported />
                            )}

                          {typeDisplay !== typeDisplayAnnotation.UNSUPPORT &&
                            pdfAnnotationModule !==
                              pdfAnnotationModules.pdfTools && <Toolbox />}

                          <Tooltip />

                          {typeDisplay !== typeDisplayAnnotation.UNSUPPORT &&
                            !isLoadingPage && (
                              <>
                                {pdfAnnotationModule !==
                                  pdfAnnotationModules.pdfTools && (
                                  <PanelComment
                                    publicCommentCount={publicComments.length}
                                    internalCommentCount={
                                      internalComments.length
                                    }
                                    isShowCommentPanel={isShowCommentPanel}
                                    commentThreads={commentThreadPanel}
                                    onSelectedComment={handleSelectedComment}
                                    onTogglePanel={handleTogglePanelComment}
                                  />
                                )}

                                <PanelVersion
                                  onTogglePanel={handleTogglePanelVersion}
                                  pdfAnnotationModule={pdfAnnotationModule}
                                />
                              </>
                            )}

                          {useNavigation && (
                            <Navigation
                              onNext={handleNavRight}
                              onPrev={handleNavLeft}
                            />
                          )}
                        </PdfContext.Provider>
                      </ImgContext.Provider>
                      {/* <ContextMenu /> */}
                    </AnnotationActionContext.Provider>
                  </ContextMenuContext.Provider>
                </HeaderAnnotationContext.Provider>
              </TooltipContext.Provider>
            </PreviewContext.Provider>
          </ShapeContext.Provider>
        </VersionContext.Provider>
      </LoaderContext.Provider>
    </div>
  )
}

export default Annotation
